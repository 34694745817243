import { Action } from '../shared/common.action';
import { AuditState, Audit, AuditRelationships } from './audits.models';
import { AuditAction } from './audits.actions';
import { JSONAPIDeserializerService } from '../shared/jsonapi-deserializer.service';
import { Utils } from '../shared/utils';

export default function reducer(state = new AuditState(), action: Action): AuditState {

  const deserializer = new JSONAPIDeserializerService<Audit, AuditRelationships>(Audit.fromJSONAPI, AuditRelationships.fromJSONAPI);

  switch (action.type) {

    case AuditAction.LOAD: {
      return <AuditState> state.withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.incrementIsBusy(action, isBusy))
      );
    }

    case AuditAction.LOAD_SUCCESS: {
      const { entities, relationships } = deserializer.deserialize(action.payload.response);
      return <AuditState>Utils.updateState<AuditState>(state, entities, relationships, action)
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy));
    }

    case AuditAction.LOAD_FAIL: {
      return <AuditState> Utils.updateDiff(state, action).withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy))
      );
    }

    case AuditAction.LOAD_HISTORY: {
      return <AuditState> state.withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.incrementIsBusy(action, isBusy))
      );
    }

    case AuditAction.CLEAR_HISTORY: {
      return <AuditState>state
        .updateIn(['history'], history => []);
    }

    case AuditAction.GET_HISTORY_SUCCESS: {
      return <AuditState>state
        .updateIn(['history'], history => action.payload.response)
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy));
    }

    default:
      return state;
  }
}
