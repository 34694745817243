import { IAudit } from '../connector';
import { isNullOrUndefined } from 'util';
import { IPayload } from '../shared';

export class AuditAction {
  static key = 'Audit';

  static LOAD = `[${AuditAction.key}] Load`;
  static LOAD_SUCCESS = `[${AuditAction.key}] Load success`;
  static LOAD_FAIL = `[${AuditAction.key}] Load fail`;

  static LOAD_HISTORY = `[${AuditAction.key}] Load history`;
  static GET_HISTORY_SUCCESS = `[${AuditAction.key}] Get history success`;

  static CLEAR_HISTORY = `[${AuditAction.key}] Clear history`;

  public load(type: string, ids: string[], fromDate?: number, toDate?: number, limit?: number) {
    const payload = { query: {} };
    /* Set the query */
    payload.query[type] = ids;
    if (!isNullOrUndefined(fromDate)) {
      payload['fromDate'] = fromDate;
    }
    if (!isNullOrUndefined(toDate)) {
      payload['toDate'] = toDate;
    }
    if (!isNullOrUndefined(limit)) {
      payload['limit'] = limit;
    }
    return {
      type: AuditAction.LOAD,
      payload: payload
    };
  }

  public loadHistory(requestObj: any) {
    const payload = { query: {} };
    /* Set the query */
    if (!isNullOrUndefined(requestObj.baID)) {
      payload['baID'] = requestObj.baID;
    }
    if (!isNullOrUndefined(requestObj.modelIDs)) {
      payload['modelIDs'] = requestObj.modelIDs;
    }
    if (!isNullOrUndefined(requestObj.nodeTypes)) {
      payload['nodeTypes'] = requestObj.nodeTypes;
    }
    if (!isNullOrUndefined(requestObj.suggestions)) {
      payload['suggestions'] = requestObj.suggestions;
    }
    if (!isNullOrUndefined(requestObj.type)) {
      payload['type'] = requestObj.type;
    }
    if (!isNullOrUndefined(requestObj.nodeID)) {
      payload['nodeID'] = requestObj.nodeID;
      payload['nodeDataID'] = requestObj.nodeDataID;
    }
    if (!isNullOrUndefined(requestObj.nodeDataIds)) {
      payload['nodeDataIds'] = requestObj.nodeDataIds;
    }
    if (!isNullOrUndefined(requestObj.pagination)) {
      payload['pagination'] = requestObj.pagination;
    }
    if (!isNullOrUndefined(requestObj.currentPage)) {
      payload['currentPage'] = requestObj.currentPage;
    }
    return {
      type: AuditAction.LOAD_HISTORY,
      payload: payload
    };
  }

  public clearHistory() {
    return {
      type: AuditAction.CLEAR_HISTORY,
      payload: null
    };
  }

  public loadSuccess(request: IPayload, data: IAudit | IAudit[]) {
    return {
      type: AuditAction.LOAD_SUCCESS,
      payload: {
        request: request,
        response: data
      }
    };
  }

  public loadFail(error: any) {
    return {
      type: AuditAction.LOAD_FAIL,
      payload: {
        request: undefined,
        response: error
      }
    };
  }

  public loadGetHistorySuccess(request: IPayload, response: any) {
    return {
      type: AuditAction.GET_HISTORY_SUCCESS,
      payload: {
        request: request,
        response: response
      }
    };
  }
}
