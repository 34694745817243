import { GoAPI } from 'src/app/shared/api/connector';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';

import { Action } from '../shared/common.action';
import { API } from '../connector/shared';
import { CommonEffects } from '../shared';
import { AuditAction } from './audits.actions';

@Injectable()
export class AuditEffects {

  @Effect() loadAudits$ = this.actions$
    .pipe(ofType(AuditAction.LOAD))
    .mergeMap((action: Action) => this.api.audits.post(action.payload).mergeMap(response => Observable.from([
      this.auditAction.loadSuccess(action.payload, response.data)
    ]))).catch((error: any) => Observable.of(this.auditAction.loadFail(error)));

  @Effect() getHistory$ = this.actions$
    .pipe(ofType(AuditAction.LOAD_HISTORY))
    .mergeMap((action: Action) => this.goAPI.getHistory(action.payload.baID).post(action.payload).mergeMap((response: any) => Observable.from([
      this.auditAction.loadGetHistorySuccess(action.payload, response)
    ]))).catch((error: any) => Observable.of(this.auditAction.loadFail(error)));

  constructor(private api: API,
              private goAPI: GoAPI,
              private actions$: Actions,
              private commonEffects: CommonEffects,
              private auditAction: AuditAction) {}

}
