import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs/Observable';
import { API, JSONAPIResponse, IBusinessarea, GoAPI } from '../connector';

import { BusinessareaAction } from './businessareas.actions';
import { CommonEffects } from '../shared/common.effects';
import { Action } from '../shared/common.action';
import { from } from 'rxjs';

@Injectable()
export class BusinessareaEffects {

  private concurrency = 5;

  @Effect() loadBusinessarea$ = this.actions$
    .pipe(ofType(BusinessareaAction.LOAD))
    .mergeMap((action: Action) => this.api.businessareas
      .include(action.payload.includes)
      .find(action.payload.id)
      .mergeMap((response: JSONAPIResponse<IBusinessarea>) =>
        this.commonEffects.getObservables(this.businessareaAction.loadSuccess, action.payload.includes, response)
      )
    ).catch((error: any) => Observable.of(this.businessareaAction.loadFail(error)));

  @Effect() loadGoBusinessarea$ = this.actions$
    .pipe(ofType(BusinessareaAction.LOAD_GO))
    .mergeMap((action: Action) => this.apiGo.businessareas
      .findAll()
      .mergeMap((response: JSONAPIResponse<IBusinessarea[]>) =>
        from([this.businessareaAction.loadSuccess(response.toPayload())])
      )
    ).catch((error: any) => Observable.of(this.businessareaAction.loadFail(error)));

  @Effect() loadBusinessareaActions$ = this.actions$
    .pipe(ofType(BusinessareaAction.LOAD_ACTIONS))
    .mergeMap((action: Action) => this.apiGo.businessarea(action.payload.id).actions.get()
      .mergeMap((response: JSONAPIResponse<any[]>) =>
        from([this.businessareaAction.loadActionsSuccess(action.payload, response)])
      )
    ).catch((error: any) => Observable.of(this.businessareaAction.loadActionsFail(error)));

  @Effect() massLoadBusinessarea$ = this.actions$
    .pipe(ofType(BusinessareaAction.MASSLOAD))
    .mergeMap((action: Action) => this.api.businessareas.mass(action.payload.id)
      .mergeMap((response: JSONAPIResponse<IBusinessarea>) =>
        this.commonEffects.getMassBusinessareaObservables(this.businessareaAction.loadSuccess, response)
      )
    ).catch((error: any) => Observable.of(this.businessareaAction.loadFail(error)));


  @Effect() massGoLoadBusinessarea$ = this.actions$
    .pipe(ofType(BusinessareaAction.MASSGOLOAD))
    .mergeMap((action: Action) => this.apiGo.businessareas.mass(action.payload.id)
      .mergeMap((response: JSONAPIResponse<IBusinessarea>) =>
        this.commonEffects.getMassBusinessareaObservables(this.businessareaAction.loadSuccess, response)
      )
    ).catch((error: any) => Observable.of(this.businessareaAction.loadFail(error)));

  @Effect() loadBusinessareasByInstance$ = this.actions$
    .pipe(ofType(BusinessareaAction.LOAD_BY_INSTANCE))
    .mergeMap((action: Action) => this.api.instance(action.payload.instanceId).businessareas
      .include(action.payload.includes)
      .findAll()
      .mergeMap((response: JSONAPIResponse<IBusinessarea[]>) =>
        this.commonEffects.getObservables(this.businessareaAction.loadSuccess, action.payload.includes, response)
      )
    ).catch((error: any) => Observable.of(this.businessareaAction.loadFail(error)));

  @Effect() createBusinessarea$ = this.actions$
    .pipe(ofType(BusinessareaAction.CREATE))
    .filter((action: Action) => !!action.payload)
    .mergeMap((action: Action) => Observable.of(...action.payload.data.map(payload => {
      const data = payload.data.toJS();
      delete data.id;
      return this.api.instance(action.payload.instanceId).businessareas.create(data)
        .mergeMap(response => Observable.from([
          this.businessareaAction.createSuccess(payload, response.toPayload())
        ]))
        .catch((error: any) => Observable.of(this.businessareaAction.createFail(payload, error)));
    })))
    .mergeAll(this.concurrency);

  @Effect() createBusinessareaGo$ = this.actions$
    .pipe(ofType(BusinessareaAction.CREATE_GO))
    .filter((action: Action) => !!action.payload)
    .mergeMap((action: Action) => Observable.of(...action.payload.data.map(payload => {
      const data = payload.data.toJS();
      delete data.id;
      return this.apiGo.instance(action.payload.instanceId).businessareas.create(data)
        .mergeMap(response => Observable.from([
          this.businessareaAction.createSuccess(payload, response.toPayload())
        ]))
        .catch((error: any) => Observable.of(this.businessareaAction.createFail(payload, error)));
    })))
    .mergeAll(this.concurrency);

  @Effect() removeBusinessarea$ = this.actions$
    .pipe(ofType(BusinessareaAction.REMOVE))
    .filter((action: Action) => !!action.payload)
    .mergeMap((action: Action) => Observable.of(...action.payload.data.map(payload => this.api.businessareas.remove(payload)
      .mergeMap(response => Observable.from([
        this.businessareaAction.removeSuccess(payload, [response.toPayload()])
      ]))
      .catch((error: any) => Observable.of(this.businessareaAction.removeFail(payload, error)))
    )))
    .mergeAll(this.concurrency);

  @Effect() removeBusinessareaGo$ = this.actions$
    .pipe(ofType(BusinessareaAction.REMOVE_GO))
    .filter((action: Action) => !!action.payload)
    .mergeMap((action: Action) => this.apiGo.businessareas.bulkRemove([action.payload.data[0]])
      .mergeMap(response => {
        return Observable.from([
          this.businessareaAction.removeSuccess(action.payload, response.toPayload())
        ]
        )
      })
      .catch((error: any) => Observable.of(this.businessareaAction.removeFail(action.payload, error)))
    );

  @Effect() updateBusinessarea$ = this.actions$
    .pipe(ofType(BusinessareaAction.UPDATE))
    .filter((action: Action) => !!action.payload)
    .mergeMap((action: Action) => Observable.of(...action.payload.map(payload => this.api.businessareas.update(payload.id, payload.data)
      .mergeMap(response => Observable.from([
        this.businessareaAction.updateSuccess(payload, [response.toPayload()])
      ]))
      .catch((error: any) => Observable.of(this.businessareaAction.updateFail(payload, error)))
    )))
    .mergeAll(this.concurrency);

  @Effect() updateBusinessareaGo$ = this.actions$
    .pipe(ofType(BusinessareaAction.UPDATE_GO))
    .filter((action: Action) => !!action.payload)
    .mergeMap((action: Action) => {
      return this.apiGo.businessareas.bulkUpdate([action.payload[0]])
        .mergeMap(response => Observable.from([
          this.businessareaAction.updateSuccess(action.payload, response.toPayload())
        ]))
        .catch((error: any) => Observable.of(this.businessareaAction.updateFail(action.payload, error)))
    });

  @Effect() duplicateBusinessarea$ = this.actions$
    .pipe(ofType(BusinessareaAction.DUPLICATE))
    .filter((action: Action) => !!action.payload)
    .mergeMap((action: Action) => Observable.of(...action.payload.data.map(payload => {
      const data = payload.data;
      return this.api.instance(action.payload.instanceId).businessareas.duplicate(data)
        .mergeMap(response => Observable.from([
          this.businessareaAction.duplicateSuccess(payload, response.toPayload())
        ]))
        .catch((error: any) => Observable.of(this.businessareaAction.duplicateFail(payload, error)));
    })))
    .mergeAll(this.concurrency);


  constructor(private api: API,
    private apiGo: GoAPI,
    private actions$: Actions,
    private commonEffects: CommonEffects,
    private businessareaAction: BusinessareaAction) { }
}
