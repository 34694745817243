import { EventEmitter, forwardRef, Inject, Injectable } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';
import { share } from 'rxjs/operators';

import { ILocalStorageEvent } from './local-storage-events.interface';
import { INotifyOptions } from './notify-options.interface';
import { ILocalStorageServiceConfig } from './local-storage.config.interface';
import { SettingsAction, SettingsService } from '../../api/settings';
import { IPayload } from '../../api/shared';
import { isNullOrUndefined, isString } from 'util';
import { Map } from 'immutable';

const DEPRECATED = 'This function is deprecated.';
const LOCAL_STORAGE_NOT_SUPPORTED = 'LOCAL_STORAGE_NOT_SUPPORTED';

@Injectable()
export class LocalStorageService {

  public fromBackend = true;

  public isSupported = false;

  public errors$: Observable<string>;
  public removeItems$: Observable<ILocalStorageEvent>;
  public setItems$: Observable<ILocalStorageEvent>;
  public warnings$: Observable<string>;

  public afterUpdate = new EventEmitter();
  public loaded = new EventEmitter();

  private notifyOptions: INotifyOptions = {
    setItem: false,
    removeItem: false
  };
  private prefix = 'ls';
  private storageType: 'sessionStorage' | 'localStorage' = 'localStorage';
  private webStorage: Storage;

  private errors: Subscriber<string> = new Subscriber<string>();
  private removeItems: Subscriber<ILocalStorageEvent> = new Subscriber<ILocalStorageEvent>() ;
  private setItems: Subscriber<ILocalStorageEvent> = new Subscriber<ILocalStorageEvent>();

  private settingsMap = Map<string, any>();
  private warnings: Subscriber<string> = new Subscriber<string>();

  constructor (
    @Inject('LOCAL_STORAGE_SERVICE_CONFIG') config: ILocalStorageServiceConfig,
    @Inject(forwardRef(() => SettingsService)) private settingsService: SettingsService
  ) {

    const { notifyOptions, prefix, storageType } = config;

    /* Store the settings on change in a map */
    this.settingsService.all(false).subscribe((settings: any) => {
      this.settingsMap = settings;
      this.loaded.emit();
    });

    if (notifyOptions != null) {
      const { setItem, removeItem } = notifyOptions;
      this.setNotify(!!setItem, !!removeItem);
    }
    if (prefix != null) {
      this.setPrefix(prefix);
    }
    if (storageType != null) {
      this.setStorageType(storageType);
    }

    this.settingsService.diff.filter(diff => diff.action === SettingsAction.UPDATE_SUCCESS || diff.action === SettingsAction.UPDATE_FAIL).subscribe(diff => this.afterUpdate.emit(diff));

    this.errors$ = new Observable<string>((observer: Subscriber<string>) => this.errors = observer).pipe(share());
    this.removeItems$ = new Observable<ILocalStorageEvent>((observer: Subscriber<ILocalStorageEvent>) => this.removeItems = observer).pipe(share());
    this.setItems$ = new Observable<ILocalStorageEvent>((observer: Subscriber<ILocalStorageEvent>) => this.setItems = observer).pipe(share());
    this.warnings$ = new Observable<string>((observer: Subscriber<string>) => this.warnings = observer).pipe(share());

    this.isSupported = this.checkSupport();
  }

  public add (key: string, value: any): Promise<boolean> {
    if (console && console.warn) {
      console.warn(DEPRECATED);
      console.warn('Use `LocalStorageService.set` instead.');
    }

    return this.set(key, value);
  }

  public clearAll (removeSettings = true, regularExpression?: string, callback?: Function): boolean {
    // Setting both regular expressions independently
    // Empty strings result in catchall RegExp
    const prefixRegex = !!this.prefix ? new RegExp('^' + this.prefix) : new RegExp('');
    const testRegex = !!regularExpression ? new RegExp(regularExpression) : new RegExp('');

    if (!this.isSupported) {
      this.warnings.next(LOCAL_STORAGE_NOT_SUPPORTED);
      return false;
    }

    const prefixLength = this.prefix.length;

    for (const key in this.webStorage) {
      // Only remove items that are for this app and match the regular expression
      if (prefixRegex.test(key) && testRegex.test(key.substr(prefixLength))) {
        try {
          this.remove(key.substr(prefixLength));
        } catch (e) {
          this.errors.next(e.message);
          return false;
        }
      }
    }

    if (removeSettings && this.fromBackend) {
      if (!isNullOrUndefined(callback)) {
        this.settingsService.diff.filter(diff => diff.action === SettingsAction.REMOVE_SUCCESS || diff.action === SettingsAction.REMOVE_FAIL).subscribe(diff => {
          callback();
        });
      }
      this.settingsService.remove();
    }

    return true;
  }

  public deriveKey (key: string): string {
    return `${this.prefix}${key}`;
  }

  public get <T> (key: string): T {
    if (!this.isSupported) {
      this.warnings.next(LOCAL_STORAGE_NOT_SUPPORTED);
      return null;
    }

    const derivedKey = this.deriveKey(key);

    let syncedItem: any;
    if (this.fromBackend) {
      syncedItem = this.settingsMap.get(derivedKey);
    }

    const item = !isNullOrUndefined(syncedItem) ? syncedItem : (this.webStorage ? this.webStorage.getItem(derivedKey) : null);
    if (!item || item === 'null') {
      return null;
    }

    try {
      return JSON.parse(item);
    } catch (e) {
      return item;
    }
  }

  public getStorageType (): string {
    return this.storageType;
  }

  public keys (): Array<string> {
    if (!this.isSupported) {
      this.warnings.next(LOCAL_STORAGE_NOT_SUPPORTED);
      return [];
    }

    const prefixLength = this.prefix.length;
    const keys: Array<string> = [];
    for (const key in this.webStorage) {
      // Only return keys that are for this app
      if (key.substr(0, prefixLength) === this.prefix) {
        try {
          keys.push(key.substr(prefixLength));
        } catch (e) {
          this.errors.next(e.message);
          return [];
        }
      }
    }
    return keys;
  }

  public length (): number {
    let count = 0;
    const storage = this.webStorage;
    for (let i = 0; i < storage.length; i++) {
      if (storage.key(i).indexOf(this.prefix) === 0) {
        count += 1;
      }
    }
    return count;
  }

  public remove (...keys: Array<string>): boolean {
    let result = true;
    const toBackend = [];
    keys.forEach((key: string) => {
      if (!this.isSupported) {
        this.warnings.next(LOCAL_STORAGE_NOT_SUPPORTED);
        result = false;
      }

      /* Store the setting on backend */
      if (this.fromBackend) {
        this.settingsMap = this.settingsMap.remove(this.deriveKey(key));
        toBackend.push(<IPayload> {
          id: this.deriveKey(key),
          data: undefined
        });
      }

      try {
        this.webStorage.removeItem(this.deriveKey(key));
        if (this.notifyOptions.removeItem) {
          this.removeItems.next({
            key: key,
            storageType: this.storageType
          });
        }
      } catch (e) {
        this.errors.next(e.message);
        result = false;
      }
    });
    if (this.fromBackend) {
      this.settingsService.update(toBackend);
    }
    return result;
  }

  public set (key: string, value: any): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      // Let's convert `undefined` values to `null` to get the value consistent
      if (value === undefined) {
        value = null;
      } else {
        value = JSON.stringify(value);
      }

      if (!this.isSupported) {
        this.warnings.next(LOCAL_STORAGE_NOT_SUPPORTED);
        resolve(false);
      }

      /* Store the setting on backend */
      const saveValue = isString(value) ? value : value;
      if (this.fromBackend) {
        const dkey = this.deriveKey(key);
        this.settingsMap = this.settingsMap.set(dkey, saveValue);
        this.settingsService.diff.filter(diff => !isNullOrUndefined(diff) && diff.action === SettingsAction.UPDATE_SUCCESS && diff.payload[0].id === dkey).take(1).subscribe(() => resolve(true));
        this.settingsService.diff.filter(diff => !isNullOrUndefined(diff) && diff.action === SettingsAction.UPDATE_FAIL && diff.payload[0].id === dkey).take(1).subscribe(() => resolve(false));
        this.settingsService.update(<IPayload> {
          id: dkey,
          data: saveValue
        });
      }

      try {
        if (this.webStorage) {
          this.webStorage.setItem(this.deriveKey(key), value);
        }
        if (this.notifyOptions.setItem) {
          this.setItems.next({
            key: key,
            newvalue: value,
            storageType: this.storageType
          });
        }
      } catch (e) {
        this.errors.next(e.message);
        resolve(false);
      }
      if (!this.fromBackend) {
        resolve(true);
      }
    });
  }

  private checkSupport (): boolean {
    try {
      const supported = this.storageType in window
        && window[this.storageType] !== null;

      if (supported) {
        this.webStorage = window[this.storageType];

        // When Safari (OS X or iOS) is in private browsing mode, it
        // appears as though localStorage is available, but trying to
        // call .setItem throws an exception.
        //
        // "QUOTA_EXCEEDED_ERR: DOM Exception 22: An attempt was made
        // to add something to storage that exceeded the quota."
        const key = this.deriveKey(`__${Math.round(Math.random() * 1e7)}`);
        this.webStorage.setItem(key, '');
        this.webStorage.removeItem(key);
      }

      return supported;
    } catch (e) {
      this.errors.next(e.message);
      return false;
    }
  }

  private setPrefix (prefix: string): void {
    this.prefix = prefix;

    // If there is a prefix set in the config let's use that with an appended
    // period for readability:
    const PERIOD = '.';
    if (this.prefix && !this.prefix.endsWith(PERIOD)) {
      this.prefix = !!this.prefix ? `${this.prefix}${PERIOD}` : '';
    }
  }

  private setStorageType (storageType: 'sessionStorage' | 'localStorage'): void {
    this.storageType = storageType;
  }

  private setNotify (setItem: boolean, removeItem: boolean): void {
    if (setItem != null) {
      this.notifyOptions.setItem = setItem;
    }
    if (removeItem != null) {
      this.notifyOptions.removeItem = removeItem;
    }
  }
}
