
import { Action } from '../shared/common.action';
import { BusinessareaState, Businessarea, BusinessareaRelationships } from './businessareas.models';
import { Utils, JSONAPIDeserializerService } from '../shared';
import { BusinessareaAction } from './businessareas.actions';

export default function reducer(state = new BusinessareaState(), action: Action): BusinessareaState {
  const deserializer = new JSONAPIDeserializerService<Businessarea, BusinessareaRelationships>(Businessarea.fromJSONAPI, BusinessareaRelationships.fromJSONAPI);
  switch (action.type) {

    case BusinessareaAction.LOAD:
      return <BusinessareaState>state.withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.incrementIsBusy(action, isBusy))
      );

    case BusinessareaAction.LOAD_GO:
      return <BusinessareaState>state.withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.incrementIsBusy(action, isBusy))
      );

    case BusinessareaAction.MASSLOAD:
      return <BusinessareaState>state.withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.incrementIsBusy(action, isBusy))
      );

    case BusinessareaAction.MASSGOLOAD:
      return <BusinessareaState>state.withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.incrementIsBusy(action, isBusy))
      );

    case BusinessareaAction.MASSLOAD_SUCCESS:
      return Utils.updateDiff(state, action);

    case BusinessareaAction.LOAD_SUCCESS: {
      const { entities, relationships } = deserializer.deserialize(action.payload.response);
      return <BusinessareaState>Utils.updateState<BusinessareaState>(state, entities, relationships)
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy));
    }

    case BusinessareaAction.LOAD_FAIL: {
      return <BusinessareaState>Utils.updateDiff(state, action).withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy))
      );
    }

    case BusinessareaAction.CREATE: {
      return <BusinessareaState>state.withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.incrementIsBusy(action, isBusy))
      );
    }

    case BusinessareaAction.CREATE_SUCCESS: {
      const { entities, relationships } = deserializer.deserialize(action.payload.response);
      return <BusinessareaState>Utils.updateState<BusinessareaState>(state, entities, relationships, action)
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy));
    }

    case BusinessareaAction.CREATE_FAIL: {
      return <BusinessareaState>Utils.updateDiff(state, action).withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy))
      );
    }

    case BusinessareaAction.DUPLICATE: {
      return <BusinessareaState>state.withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => ++isBusy)
      );
    }

    case BusinessareaAction.DUPLICATE_SUCCESS: {
      const { entities, relationships } = deserializer.deserialize(action.payload.response);
      return <BusinessareaState>Utils.updateState<BusinessareaState>(state, entities, relationships, action)
        .updateIn(['isBusy'], (isBusy: number) => (isBusy === 0 ? 0 : --isBusy));
    }

    case BusinessareaAction.DUPLICATE_FAIL: {
      return <BusinessareaState>Utils.updateDiff(state, action).withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => (isBusy === 0 ? 0 : --isBusy))
      );
    }

    case BusinessareaAction.UPDATE: {
      return <BusinessareaState>state.withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.incrementIsBusy(action, isBusy))
      );
    }

    case BusinessareaAction.UPDATE_SUCCESS: {
      const { entities, relationships } = deserializer.deserialize(action.payload.response);
      return <BusinessareaState>Utils.updateState<BusinessareaState>(state, entities, relationships, action)
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy));
    }

    case BusinessareaAction.UPDATE_FAIL: {
      return <BusinessareaState>Utils.updateDiff(state, action).withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy))
      );
    }

    case BusinessareaAction.REMOVE: {
      return <BusinessareaState>state.withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.incrementIsBusy(action, isBusy))
      );
    }

    case BusinessareaAction.REMOVE_SUCCESS: {
      const { entities, relationships } = deserializer.deserialize(action.payload.response);
      return <BusinessareaState>Utils.deleteFromState<BusinessareaState>(state, entities, relationships, action)
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy));
    }

    case BusinessareaAction.REMOVE_FAIL: {
      return <BusinessareaState>Utils.updateDiff(state, action).withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy))
      );
    }

    case BusinessareaAction.LOAD_ACTIONS: {
      return <BusinessareaState>state.withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.incrementIsBusy(action, isBusy))
      );
    }

    case BusinessareaAction.LOAD_ACTIONS_SUCCESS: {
      return <BusinessareaState>Utils.updateDiff(state, action).withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy))
      );
    }

    case BusinessareaAction.LOAD_ACTIONS_FAIL: {
      return <BusinessareaState>Utils.updateDiff(state, action).withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy))
      );
    }

    default: {
      return state;
    }
  }
}
